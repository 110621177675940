import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import BASE_URL from '../../../config/config'
import { jwtDecode } from "jwt-decode"

const SingleProyecto = () => {
  const { id } = useParams() // Se asume que la ruta incluye el parámetro id
  const navigate = useNavigate()
  const [proyecto, setProyecto] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const token = localStorage.getItem("AuthToken")
  let tokenInfo = {}
  if (token) {
    tokenInfo = jwtDecode(token)
  }

  useEffect(() => {
    axios.get(`${BASE_URL}/proyecto/${id}`)
      .then(response => {
        setProyecto(response.data.proyecto)
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setError('Error al cargar el proyecto')
        setLoading(false)
      })
  }, [id])

  // Redirige al /dashboard si el usuario no es admin ni coincide con el usuario autorizado del proyecto
  useEffect(() => {
    if (!loading && proyecto) {
      if (tokenInfo.role !== "admin" && tokenInfo.usuario !== proyecto.usuario_autorizado) {
        navigate("/dashboard")
      }
    }
  }, [loading, proyecto, tokenInfo, navigate])

  if (loading) return <div>Cargando...</div>
  if (error) return <div>{error}</div>
  if (!proyecto) return <div>No se encontró el proyecto.</div>

  return (
    <main className='site-width'>
      <div className="single-proyecto">
        {tokenInfo.role === "admin" ? (
          <>
            <h2>{proyecto.nombre} ({proyecto.tipo_de_proyecto})</h2>
            <p className='tipo-proyecto'>
              Tipo de proyecto: <b>{proyecto.tipo_de_proyecto}</b>
            </p>
          </>
        ) : (
          <>
            <h2>¡Bienvenido a tu proyecto {proyecto.nombre}!</h2>
            <p className='tipo-proyecto'>
              Tipo de proyecto: <b>{proyecto.tipo_de_proyecto}</b>
            </p>
            <p>
              Visualiza el estado actual de desarrollo de tu proyecto.
            </p>
          </>
        )}

        <div className="grid-timeline">
          <div className="texto-pre-timeline">
            <h3>Timeline de tu proyecto</h3>
            {proyecto.comprobacion_final === "aceptado" ?                 
              <p>¡Tu proyecto finalizó!</p>
              :
              <p>Tu proyecto no está listo todavía.</p>    
            }
          </div>
          <div className="timeline-proyecto">
            <div className={`item-timeline proyecto-${proyecto.prototipo}`}>
              <div className='icono-timeline'>
                <img src="/images/cohete.svg" alt="Icono" />
                {proyecto.prototipo === "aceptado" &&  
                  <img src="/images/controlar.svg" className='check-aceptado' alt="Icono" />
                }
              </div>
              <div className="texto-timeline">
                <h3>Fase 1 - Prototipo</h3>
                <p>
                  Desarrollo del prototipo inicial y validación del concepto con wireframes interactivos.
                </p>
                {proyecto.prototipo === "en_proceso" &&  
                  <p className='texto-timeline-en_proceso'>
                    Estamos trabajando en esta fase del proyecto.
                  </p>                    
                }
                {proyecto.prototipo === "aceptado" &&  
                  <p className='texto-timeline-aceptado'>
                    Esta fase se validó correctamente.
                  </p>                    
                }
              </div>
            </div>

            <div className={`item-timeline proyecto-${proyecto.diseno}`}>
              <div className='icono-timeline'>
                <img src="/images/paleta-de-color.svg" alt="Icono" />
                {proyecto.diseno === "aceptado" &&  
                  <img src="/images/controlar.svg" className='check-aceptado' alt="Icono" />
                }
              </div>
              <div className="texto-timeline">
                <h3>Fase 2 - Diseño</h3>
                <p>
                  Creación de la identidad visual, diseño de interfaces y experiencia de usuario.
                </p>
                {proyecto.diseno === "en_proceso" &&  
                  <p className='texto-timeline-en_proceso'>
                    Estamos trabajando en esta fase del proyecto.
                  </p>                    
                }
                {proyecto.diseno === "aceptado" &&  
                  <p className='texto-timeline-aceptado'>
                    Esta fase se validó correctamente.
                  </p>                    
                }
              </div>
            </div>

            <div className={`item-timeline proyecto-${proyecto.desarrollo}`}>
              <div className='icono-timeline'>
                <img src="/images/codificacion.svg" alt="Icono" />
                {proyecto.desarrollo === "aceptado" &&  
                  <img src="/images/controlar.svg" className='check-aceptado' alt="Icono" />
                }
              </div>
              <div className="texto-timeline">
                <h3>Fase 3 - Desarrollo</h3>
                <p>
                  Implementación técnica, desarrollo del código y pruebas de funcionalidad.
                </p>
                {proyecto.desarrollo === "en_proceso" &&  
                  <p className='texto-timeline-en_proceso'>
                    Estamos trabajando en esta fase del proyecto.
                  </p>                    
                }
                {proyecto.desarrollo === "aceptado" &&  
                  <p className='texto-timeline-aceptado'>
                    Esta fase se validó correctamente.
                  </p>                    
                }
              </div>
            </div>

            <div className={`item-timeline proyecto-${proyecto.contenido}`}>
              <div className='icono-timeline'>
                <img src="/images/lapiz.svg" alt="Icono" />
                {proyecto.contenido === "aceptado" &&  
                  <img src="/images/controlar.svg" className='check-aceptado' alt="Icono" />
                }
              </div>
              <div className="texto-timeline">
                <h3>Fase 4 - Contenido</h3>
                <p>
                  Tu sitio web tiene el contenido suficiente para funcionar.
                </p>
                {proyecto.contenido === "en_proceso" &&  
                  <p className='texto-timeline-en_proceso'>
                    Estamos trabajando en esta fase del proyecto.
                  </p>                    
                }
                {proyecto.contenido === "aceptado" &&  
                  <p className='texto-timeline-aceptado'>
                    Esta fase se validó correctamente.
                  </p>                    
                }
              </div>
            </div>

            <div className={`item-timeline proyecto-${proyecto.comprobacion_final}`}>
              <div className='icono-timeline'>
                <img src="/images/verificar.svg" alt="Icono" />
                {proyecto.comprobacion_final === "aceptado" &&  
                  <img src="/images/controlar.svg" className='check-aceptado' alt="Icono" />
                }
              </div>
              <div className="texto-timeline">
                <h3>Fase 5 - Validación web</h3>
                <p>
                  El técnico asignado a tu proyecto ha revisado la vinculación de métricas, cookies y políticas legales.
                </p>
                {proyecto.comprobacion_final === "en_proceso" &&  
                  <p className='texto-timeline-en_proceso'>
                    Estamos trabajando en esta fase del proyecto.
                  </p>                    
                }
                {proyecto.comprobacion_final === "aceptado" &&  
                  <p className='texto-timeline-aceptado'>
                    Esta fase se validó correctamente.
                  </p>                    
                }
              </div>
            </div>

          </div>      
        </div>     
      </div>
    </main>
  )
}

export default SingleProyecto
