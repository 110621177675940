import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Button, CalendarCell, CalendarGrid, Heading, RangeCalendar } from 'react-aria-components'
import { CalendarDate } from '@internationalized/date'
import BASE_URL from '../../../config/config'

// Componente CustomSelect con cierre al hacer clic fuera
const CustomSelect = ({ value, options, onChange }) => {
  const [open, setOpen] = useState(false)
  const currentOption = options.find(option => option.value === value)
  const selectRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  return (
    <div className="custom-select-container" ref={selectRef}>
      <div
        className="custom-select-trigger"
        onClick={() => setOpen(!open)}
      >
        {currentOption ? currentOption.label : 'Seleccionar'}
      </div>
      {open && (
        <div className="custom-select-dropdown">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => {
                onChange(option.value)
                setOpen(false)
              }}
              className={`custom-select-option ${option.value} ${option.value === value ? 'active' : ''}`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const UserSelect = ({ selectedUser, setSelectedUser }) => {
  const [users, setUsers] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [dropdownVisible, setDropdownVisible] = useState(false)

  useEffect(() => {
    axios.get(`${BASE_URL}/todos-usuarios`)
      .then(response => {
        setUsers(response.data.usuarios)
      })
      .catch(error => console.error(error))
  }, [])

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    setDropdownVisible(true)
  }

  const handleBlur = () => {
    const matchedUser = users.find(
      user => user.usuario.toLowerCase() === inputValue.trim().toLowerCase()
    )
    if (matchedUser) {
      setSelectedUser(matchedUser)
      setInputValue(matchedUser.usuario)
    } else {
      setSelectedUser(null)
    }
  }

  const filteredUsers = users.filter(user =>
    user.usuario.toLowerCase().includes(inputValue.toLowerCase())
  )

  return (
    <div className="user-select-container">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setDropdownVisible(true)}
        onBlur={() => {
          setTimeout(() => {
            setDropdownVisible(false)
            handleBlur()
          }, 200)
        }}
        placeholder="Buscar por @usuario"
      />
      {dropdownVisible && filteredUsers.length > 0 && (
        <div className="dropdown-usuarios">
          {filteredUsers.map(user => (
            <div
              key={user.id}
              onMouseDown={() => {
                setSelectedUser(user)
                setInputValue(user.usuario)
                setDropdownVisible(false)
              }}
              className="dropdown-usuario-item"
            >
              @{user.usuario} - {user.nombre} 
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const Proyectos = () => {
  const today = new CalendarDate(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate()
  )

  const [nombreProyecto, setNombreProyecto] = useState('')
  const [tipoProyecto, setTipoProyecto] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)
  const [dateRange, setDateRange] = useState({
    start: today,
    end: today
  })
  const [proyectos, setProyectos] = useState([])
  const [showModal, setShowModal] = useState(false)
  
  // Estados para filtrar y ordenar
  const [sortOrder, setSortOrder] = useState("newest")
  const [tipoFilter, setTipoFilter] = useState("")

  // Ref para detectar clics fuera del modal
  const modalRef = useRef(null)

  // Definición de estados internos y sus etiquetas para el frontend
  const estados = [
    { value: "sin_ejecutar", label: "Sin ejecutar" },
    { value: "en_proceso", label: "En proceso" },
    { value: "aceptado", label: "Aceptado" }
  ]

  // Función para obtener la lista completa de proyectos
  const fetchProyectos = () => {
    axios.get(`${BASE_URL}/proyectos`)
      .then(response => {
        const data = response.data.proyectos ? response.data.proyectos : response.data
        if (Array.isArray(data)) {
          setProyectos(data)
        } else {
          setProyectos([])
        }
      })
      .catch(error => console.error(error))
  }

  useEffect(() => {
    fetchProyectos()
  }, [])

  // Función para formatear un CalendarDate a "dd/mm/yyyy"
  const formatDate = (calendarDate) => {
    const day = String(calendarDate.day).padStart(2, '0')
    const month = String(calendarDate.month).padStart(2, '0')
    const year = calendarDate.year
    return `${day}/${month}/${year}`
  }

  const crearProyecto = async (e) => {
    e.preventDefault()
    const { start, end } = dateRange
    const inicio_proyecto = formatDate(start)
    const fin_proyecto = formatDate(end)
    const usuario_autorizado = selectedUser ? selectedUser.usuario : ''

    try {
      await axios.post(`${BASE_URL}/crear-proyecto`, {
        nombre: nombreProyecto,
        tipo_de_proyecto: tipoProyecto,
        usuario_autorizado,
        inicio_proyecto,
        fin_proyecto,
      })
      fetchProyectos()
      setShowModal(false)
      setNombreProyecto('')
      setTipoProyecto('')
      setSelectedUser(null)
    } catch (error) {
      console.error(error)
    }
  }

  // Función para actualizar el estado de un proyecto en un campo específico
  const updateEstado = async (project, field, newValue) => {
    const updatedStates = {
      prototipo: project.prototipo,
      diseno: project.diseno,
      desarrollo: project.desarrollo,
      contenido: project.contenido,
      comprobacion_final: project.comprobacion_final,
    }
    updatedStates[field] = newValue

    try {
      const response = await axios.put(`${BASE_URL}/actualizar-estados/${project.id}`, updatedStates)
      setProyectos(prev =>
        prev.map(p => p.id === project.id ? response.data.proyecto : p)
      )
    } catch (error) {
      console.error("Error al actualizar estado:", error)
    }
  }

  // Función para eliminar un proyecto
  const eliminarProyecto = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/eliminar-proyecto/${id}`)
      fetchProyectos()
    } catch (error) {
      console.error("Error al eliminar el proyecto:", error)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false)
      }
    }

    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showModal])

  // Función auxiliar para convertir una fecha en formato "dd/mm/yyyy" a objeto Date
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/")
    return new Date(year, month - 1, day)
  }

  // Filtrar proyectos por tipo (si se selecciona alguno)
  const filteredProjects = proyectos.filter(proyecto => {
    if (tipoFilter !== "") {
      return proyecto.tipo_de_proyecto === tipoFilter
    }
    return true
  })

  // Ordenar proyectos según el filtro seleccionado (más nuevo o más antiguo)
  const sortedProyectos = [...filteredProjects].sort((a, b) => {
    if (sortOrder === "newest") {
      return parseDate(b.inicio_proyecto) - parseDate(a.inicio_proyecto)
    } else {
      return parseDate(a.inicio_proyecto) - parseDate(b.inicio_proyecto)
    }
  })

  return (
    <div>
      <main className="site-width">
        <div className="grid-tracking-proyectos">
          <h2>Tracking de Proyectos</h2>
          <p>Añade un proyecto y gestiona sus estados.</p>
          <button className='anadir-proyectos' onClick={() => setShowModal(true)}>
            + Añadir proyecto
          </button>
          
          {showModal && (
            <div className="fg-overlay">
              <div className="modal-container">
                <h3>Añadir Proyecto</h3>
                <form onSubmit={crearProyecto} className="grid-crear-proyecto">
                  <div className="row-crear-proyecto-uno">
                    <div className="form-group">
                      <label>Nombre del proyecto</label>
                      <input
                        type="text"
                        value={nombreProyecto}
                        onChange={(e) => setNombreProyecto(e.target.value)}
                        placeholder="Nombre del proyecto"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Tipo de proyecto</label>
                      <select
                        value={tipoProyecto}
                        onChange={(e) => setTipoProyecto(e.target.value)}
                        required
                      >
                        <option value="">Seleccione un tipo de proyecto</option>
                        <option value="Página web">Página web</option>
                        <option value="E-commerce">E-commerce</option>
                        <option value="Kit Digital">Kit Digital</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Usuario autorizado</label>
                      <UserSelect 
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                      />
                    </div>
                  </div>
                
                  <div className="form-calendario">
                    <label>Indica el inicio y fin del proyecto:</label>
                    <RangeCalendar
                      aria-label="Fechas del proyecto"
                      defaultValue={dateRange}
                      onChange={setDateRange}
                    >
                      <header>
                        <Button slot="previous">◀</Button>
                        <Heading />
                        <Button slot="next">▶</Button>
                      </header>
                      <CalendarGrid>
                        {(date) => <CalendarCell date={date} />}
                      </CalendarGrid>
                    </RangeCalendar>
                  </div>
                  <div className="modal-actions">
                    <button type="submit" className='anadir-proyectos'>
                      Crear Proyecto
                    </button>
                    <button type="button" className='cancelar-proyecto' onClick={() => setShowModal(false)}>
                      Cancelar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Filtros usando elementos select */}
          <div className="filtro-proyectos">
            <div className="filtro-orden">
              <select value={sortOrder} onChange={e => setSortOrder(e.target.value)}>
                <option value="newest">Más Nuevo</option>
                <option value="oldest">Más Antiguo</option>
              </select>
            </div>
            <div className="filtro-tipo">
              <select value={tipoFilter} onChange={e => setTipoFilter(e.target.value)}>
                <option value="">Todos los Tipos</option>
                <option value="Página web">Página web</option>
                <option value="E-commerce">E-commerce</option>
                <option value="Kit Digital">Kit Digital</option>
              </select>
            </div>
          </div>

          <div className="tabla-proyectos">
            <div className="tabla-header">
              <div>Nombre</div>
              <div>Tipo</div>
              <div>Prototipo</div>
              <div>Diseño</div>
              <div>Desarrollo</div>
              <div>Contenido</div>
              <div>Validación web</div>
              <div>Inicio</div>
              <div>Fin</div>
            </div>
            {sortedProyectos.map(proyecto => (
              <div key={proyecto.id} className="tabla-row">
                <div className='nombre-proyecto'>
                  <button onClick={() => eliminarProyecto(proyecto.id)}>
                    <img src="/images/basura.svg" alt="Icon"/>
                  </button>
                  <Link to={`/dashboard/proyecto/${proyecto.id}`}>
                    {proyecto.nombre}
                  </Link>
                </div>
                <div>{proyecto.tipo_de_proyecto}</div>
                <div className={`estado-cell ${proyecto.prototipo}`}>
                  <CustomSelect
                    value={proyecto.prototipo}
                    options={estados}
                    onChange={(newValue) => updateEstado(proyecto, "prototipo", newValue)}
                  />
                </div>
                <div className={`estado-cell ${proyecto.diseno}`}>
                  <CustomSelect
                    value={proyecto.diseno}
                    options={estados}
                    onChange={(newValue) => updateEstado(proyecto, "diseno", newValue)}
                  />
                </div>
                <div className={`estado-cell ${proyecto.desarrollo}`}>
                  <CustomSelect
                    value={proyecto.desarrollo}
                    options={estados}
                    onChange={(newValue) => updateEstado(proyecto, "desarrollo", newValue)}
                  />
                </div>
                <div className={`estado-cell ${proyecto.contenido}`}>
                  <CustomSelect
                    value={proyecto.contenido}
                    options={estados}
                    onChange={(newValue) => updateEstado(proyecto, "contenido", newValue)}
                  />
                </div>
                <div className={`estado-cell ${proyecto.comprobacion_final}`}>
                  <CustomSelect
                    value={proyecto.comprobacion_final}
                    options={estados}
                    onChange={(newValue) => updateEstado(proyecto, "comprobacion_final", newValue)}
                  />
                </div>
                <div>{proyecto.inicio_proyecto}</div>
                <div>{proyecto.fin_proyecto}</div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  )
}

export default Proyectos
