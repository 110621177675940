import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";

import './css/style.css';
import Routering from './Routering';

Sentry.init({
    dsn: "https://914bec7b09ea0b4000a7f6ba11b619a4@o4508727097229312.ingest.de.sentry.io/4508727099064400",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, // Captura el 100% de las transacciones
    tracePropagationTargets: ["localhost", "https://soporte.agenciaadhoc.com"], // URLs donde se propagará el tracing
    // Session Replay
    replaysSessionSampleRate: 0.1, // Muestra el 10% de las sesiones
    replaysOnErrorSampleRate: 1.0, // 100% de sesiones donde ocurre un error
});

const root = createRoot(document.getElementById('root'));
root.render( <
    React.StrictMode >
    <
    Routering / >
    <
    /React.StrictMode>
);