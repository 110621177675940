import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import BASE_URL from '../../../config/config';
import { Link } from 'react-router-dom';

const UserProyectos = () => {
  const [proyectos, setProyectos] = useState([]);
  const [error, setError] = useState('');

  const token = localStorage.getItem("AuthToken");
  let tokenInfo = {};
  if (token) {
    tokenInfo = jwtDecode(token);
  }

  useEffect(() => {
    if (tokenInfo.usuario) {
      axios.get(`${BASE_URL}/proyectos/usuario/${tokenInfo.usuario}`)
        .then(response => {
          if (response.data && response.data.proyectos) {
            setProyectos(response.data.proyectos);
          }
        })
        .catch(err => {
          console.error('Error al cargar los proyectos:', err);
          setError('Error al cargar los proyectos');
        });
    }
  }, [tokenInfo.usuario]);

  return (
    <main className='site-width'>
      <div className="user-proyectos">
        <h2>Mis proyectos</h2>
        <p>Revisa los proyectos que tengas en curso.</p>
        {proyectos.length > 0 ? (
          <div className='grid-user-proyectos'>
            {proyectos.map(proyecto => (
              <Link className='item-user-proyectos' to={`/dashboard/proyecto/${proyecto.id}`} key={proyecto.id}>
                <h3>{proyecto.nombre}</h3>
                <p>Tipo de proyecto: {proyecto.tipo_de_proyecto}</p>
                {proyecto.comprobacion_final == "sin_ejecutar" ? <p className='proyecto-terminado'>Terminado</p> : <p className='proyecto-en-progreso'>En progreso</p>}
              </Link>
            ))}
          </div>
        ) : (
          <p className='error' style={{width: 'fit-content'}}>Actualmente no tienes ningún proyecto en curso o finalizado.</p>
        )}
      </div>
    </main>
  );
};

export default UserProyectos;
