import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import io from 'socket.io-client';
import PermisoDenegado from '../../components/PermisoDenegado';
import Loader from '../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../../config/config';

const Ticket = () => {
    const { identificador } = useParams();
    const [ticket, setTicket] = useState({ chat: [] });
    const [usuarioCreador, setUsuarioCreador] = useState(null);
    const [content, setContent] = useState('');
    const [socket, setSocket] = useState(null);
    const [tecnicos, setTecnicos] = useState([]);
    const [usuarioEscribiendo, setUsuarioEscribiendo] = useState(null);
    const [permisosVerificados, setPermisosVerificados] = useState(false);
    const [permisoDenegado, setPermisoDenegado] = useState(false);
    const [isSending, setIsSending] = useState(false); // Estado para controlar "Enviando..."

    const [selectedFiles, setSelectedFiles] = useState([]); // Estado para los archivos seleccionados
    const [filesToUpload, setFilesToUpload] = useState([]); // Archivos para enviar al backend
    const [showAllFiles, setShowAllFiles] = useState(false);
    const [errorAlEnviar, setErrorAlEnviar] = useState(false);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const maxFileSize = 5 * 1024 * 1024; // 5 MB
        const validFiles = [];
        const invalidFiles = [];

        files.forEach((file) => {
            if (file.size <= maxFileSize) {
                validFiles.push(file);
            } else {
                invalidFiles.push({ name: file.name, size: (file.size / (1024 * 1024)).toFixed(2) });
            }
        });

        if (invalidFiles.length > 0) {
            const errorMessage = invalidFiles
                .map((file) => `${file.name} (${file.size} MB)`)
                .join('\n');
            toast.error(
                `Los siguientes archivos exceden el tamaño permitido de 5 MB:\n${errorMessage}`,
                { position: "top-right", autoClose: 7000 }
            );
        }

        setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]); 
        setFilesToUpload((prevFiles) => [...prevFiles, ...validFiles]);

        console.log('Archivos seleccionados:', validFiles);
        console.log('Archivos rechazados:', invalidFiles);
    };

    const handleRemoveFile = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const chatRef = useRef(null);
    const bottomRef = useRef(null);

    const token = localStorage.getItem("AuthToken");
    let tokenInfo = {};
    if (token) {
        tokenInfo = jwtDecode(token);
    }

    useEffect(() => {
        const verificarPermisos = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/obtener-ticket/${identificador}`);
                const data = response.data;

                if (data.ticket) {
                    data.ticket.chat = Array.isArray(data.ticket.chat) ? data.ticket.chat : JSON.parse(data.ticket.chat);
                    setTicket(data.ticket);

                    if (data.ticket.usuario_creador !== tokenInfo.usuario && tokenInfo.role !== 'admin') {
                        setPermisoDenegado(true);
                    }
                }

                const usuarioResponse = await axios.get(`${BASE_URL}/usuario/info/${data.ticket.usuario_creador}`);
                setUsuarioCreador(usuarioResponse.data.usuarios);
            } catch (error) {
                console.error('Error al obtener el ticket:', error);
            } finally {
                setPermisosVerificados(true);
            }
        };

        verificarPermisos();
    }, [identificador, tokenInfo.usuario, tokenInfo.role]);

    useEffect(() => {
        const fetchTecnicos = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/usuario/tecnicos`);
                setTecnicos(response.data.usuarios);
            } catch (error) {
                console.error('Error al obtener la lista de técnicos:', error);
            }
        };

        fetchTecnicos();
    }, []);

    const scrollToBottom = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    };

    const scrollToTop = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = 0;
        }
    };

    useEffect(() => {
        if (ticket.estado_del_ticket === "cerrado") {
            //scrollToTop();
        } else if (ticket.chat.length > 0) {
            const timeout = setTimeout(() => {
                scrollToBottom();
            }, 100);
            return () => clearTimeout(timeout);
        }
    }, [ticket.chat, ticket.estado_del_ticket]);

    const handleChange = (value) => {
        setContent(value);
        if (socket) {
            socket.emit('escribiendo', {
                identificador: identificador,
                usuario: tokenInfo.usuario,
                nombre: tokenInfo.nombre,
                apellidos: tokenInfo.apellidos,
            });
        }
    };

    const splitFiles = (filesString) => {
        return filesString.split(",");
    };

    const getFileName = (url, length = 10) => {
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        const extensionIndex = fileName.lastIndexOf('.');
        const name = fileName.substring(0, extensionIndex);
        const extension = fileName.substring(extensionIndex);
        const shortenedName = name.length > length ? name.substring(0, length) + '...' : name;
        return shortenedName + extension;
    };

    const getIconUrl = (file) => {
        const extension = file.split('.').pop().toLowerCase();
        switch (extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return '/images/imagen-icono.svg';
            case 'psd':
            case 'gif':
            case 'zip':
            case 'docx':
            case 'doc':
            case 'svg':
            case 'pdf':
            case 'xlsx':
            case 'csv':
                return `/images/${extension}.svg`;
            default:
                return '/images/almacenamiento-de-archivos.svg';
        }
    };

    const copiarTicket = () => {
        const ticketInfo = `
        Ticket ID: ${ticket.identificador}
        Nombre: ${ticket.nombre}
        Email: ${ticket.email}
        Teléfono: ${ticket.telefono}
        Webs: ${ticket.webs_del_cliente}
        Prioridad: ${ticket.prioridad === 'urgente' ? 'Urgente' : 'No urgente'}
        Asunto: ${ticket.asunto || 'No especificado'}
        `;

        navigator.clipboard.writeText(ticketInfo)
            .then(() => {
                toast.success("Información del ticket copiada al portapapeles.", { position: "top-right" });
            })
            .catch(() => {
                toast.error("Error al copiar la información del ticket.", { position: "top-right" });
            });
    };

    const actualizarDpto = async (nuevoDpto) => {
        try {
            await axios.put(`${BASE_URL}/actualizar-dpto`, { identificador, dpto: nuevoDpto });
            setTicket((prevTicket) => ({
                ...prevTicket,
                dpto: nuevoDpto,
            }));
        } catch (error) {
            console.error('Error al actualizar el dpto:', error);
        }
    };

    const actualizarPrioridad = async (nuevaPrioridad) => {
        try {
            await axios.put(`${BASE_URL}/actualizar-prioridad`, { identificador, prioridad: nuevaPrioridad });
            setTicket((prevTicket) => ({
                ...prevTicket,
                prioridad: nuevaPrioridad,
            }));
        } catch (error) {
            console.error('Error al actualizar la prioridad:', error);
        }
    };

    const actualizarEstadoDelTicket = async (nuevoEstado) => {
        try {
            await axios.put(`${BASE_URL}/actualizar-estado_del_ticket`, { identificador, estado_del_ticket: nuevoEstado });
            setTicket((prevTicket) => ({
                ...prevTicket,
                estado_del_ticket: nuevoEstado,
            }));

            if (nuevoEstado === 'cerrado' && socket) {
                socket.emit('cerrarTicket', { identificador });
                const notificarCierre = await axios.post(`${BASE_URL}/notificar-cierre-cliente`, {
                    identificador,
                    usuario_creador: ticket.usuario_creador,
                    nombre_cliente: ticket.nombre,
                    asunto: ticket.asunto,
                });
                console.log('Notificación de cierre enviado correctamente:', notificarCierre.data);
            }
        } catch (error) {
            console.error('Error al actualizar el estado del ticket:', error);
        }
    };

    const actualizarTecnicoAsignado = async (usuarioEncargado) => {
        try {
            const response = await axios.put(`${BASE_URL}/actualizar-tecnico`, {
                identificador,
                usuario_encargado: usuarioEncargado
            });

            setTicket((prevTicket) => ({
                ...prevTicket,
                usuario_encargado: usuarioEncargado
            }));

            console.log('Técnico asignado correctamente:', response.data);

            if (usuarioEncargado !== "sin_asignar") {
                const notificarResponse = await axios.post(`${BASE_URL}/notificar-tecnico`, {
                    identificador,
                    usuario_encargado: usuarioEncargado,
                    usuario_notificador: tokenInfo.usuario,
                    usuario_creador: ticket.usuario_creador,
                    nombre_cliente: ticket.nombre,
                    apellidos_cliente: ticket.apellidos,
                    email_cliente: ticket.email,
                    webs_del_cliente: ticket.webs_del_cliente,
                    asunto: ticket.asunto,
                    descripcion: ticket.descripcion
                });

                console.log('Notificación enviada correctamente:', notificarResponse.data);
            } else {
                console.log('Ticket dejado sin asignar, no se envía notificación.');
            }

        } catch (error) {
            console.error('Error al asignar o notificar al técnico del ticket:', error.response ? error.response.data : error.message);
        }
    };

    const enviarCorreo = async (role, content, email, usuario_encargado, usuario_creador, identificador) => {
        try {
            const response = await axios.post(`${BASE_URL}/send-email`, {
                role: tokenInfo.role,
                message: content,
                email: ticket.email,
                usuario_encargado, 
                identificador,
                usuario_creador,
            });
            console.log('Correo enviado:', response.data.message);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    };

    const enviarMensaje = async () => {
        // Evitamos que se envíe si ya se está procesando un envío
        if (isSending) return;

        if ((!content || content.trim() === "" || content === "<p><br></p>") && filesToUpload.length === 0) {
            setErrorAlEnviar(true);
            return;
        }

        // Activamos el estado "Enviando..."
        setIsSending(true);

        // Si el ticket está cerrado, se reabre automáticamente
        if (ticket.estado_del_ticket === 'cerrado') {
            await actualizarEstadoDelTicket('abierto');
        }

        try {
            let uploadedFiles = [];

            if (filesToUpload.length > 0) {
                const formData = new FormData();
                filesToUpload.forEach(file => formData.append('archivoschat', file));

                const response = await axios.post(`${BASE_URL}/subir-archivos-chat/${ticket.identificador}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                uploadedFiles = response.data.archivosNuevos;
            }

            const mensajeData = {
                identificador: ticket.identificador,
                mensaje: content,
                usuario: tokenInfo.usuario,
                nombre: tokenInfo.nombre,
                apellidos: tokenInfo.apellidos,
                role: tokenInfo.role,
                fotoPerfil: tokenInfo.fotoPerfil,
                archivos: uploadedFiles,
            };

            console.log(uploadedFiles);

            socket.emit('mensaje', mensajeData);

            setErrorAlEnviar(false);
            setContent('');
            setSelectedFiles([]);
            setFilesToUpload([]);
        } catch (error) {
            console.error('Error al enviar el mensaje:', error);
        }
        
        await enviarCorreo(tokenInfo.role, content, ticket.email, ticket.usuario_encargado, ticket.usuario_creador, identificador);

        // Establece un cooldown de 3 segundos antes de permitir otro envío
        setTimeout(() => {
            setIsSending(false);
        }, 200);
    };

    useEffect(() => {
        const newSocket = io(`${BASE_URL}`);
        setSocket(newSocket);

        newSocket.on('ticketCerrado', (data) => {
            setTicket((prevTicket) => ({
                ...prevTicket,
                estado_del_ticket: 'cerrado'
            }));
        });

        return () => {
            newSocket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (socket && identificador) {
            socket.emit('joinRoom', {
                identificador: identificador,
                usuario: tokenInfo.usuario
            });
        }
    }, [identificador, socket]);

    useEffect(() => {
        if (socket) {
            socket.on('mensaje', (mensaje) => {
                setTicket((prevTicket) => ({
                    ...prevTicket,
                    chat: [...prevTicket.chat, mensaje]
                }));
            });

            let typingTimeout;
            socket.on('escribiendo', (data) => {
                if (data.usuario !== tokenInfo.usuario) {
                    setUsuarioEscribiendo(`${data.nombre} ${data.apellidos} está escribiendo...`);
                    clearTimeout(typingTimeout);
                    typingTimeout = setTimeout(() => {
                        setUsuarioEscribiendo(null);
                    }, 3000);
                }
            });
        }

        return () => {
            if (socket) {
                socket.off('mensaje');
                socket.off('escribiendo');
            }
        };
    }, [socket]);

    if (!permisosVerificados) {
        return <Loader />;
    }

    if (permisoDenegado) {
        return <PermisoDenegado />;
    }

    return (
        <main className='ticket site-width'>
            {ticket && (
                <>
                    <div className='cabecera-ticket'>
                        <div className="cabecera-ticket-uno">
                            <img src="/images/boletos-de-avion.svg" alt="Icono chat" />
                            <div className="cabecera-ticket-texto">
                                {ticket.estado_del_ticket === 'cerrado' ? (
                                    <>
                                        <h1>Este ticket ha sido cerrado</h1>
                                    </>
                                ) : (
                                    tokenInfo.role === "admin" ? (
                                        <>
                                            <h1>Asunto del ticket</h1>
                                            <p>{ticket.asunto}</p>
                                        </>
                                    ) : (
                                        <>
                                            <h1>{ticket.chat.some((msg) => msg.role === 'admin') ? 'Un técnico está revisando tu ticket' : 'Estamos procesando su ticket'}</h1>
                                            <p>{ticket.chat.some((msg) => msg.role === 'admin') ? 'Por favor, revise el chat regularmente' : 'En breve le asignaremos un técnico'}</p>
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="cabecera-ticket-dos">
                            <div className={`estado-del-ticket ${ticket.estado_del_chat === "respondido" ? 'ticket-respondido' : ''}`}>
                                <p>
                                    {ticket.estado_del_chat === "sin_responder" ? 'Esperando respuesta' : ''}
                                    {ticket.estado_del_chat === "respondido" ? 'Respondido' : ''}
                                </p>
                            </div>
                            <div className="tecnico-asignado">
                                {ticket.usuario_encargado === "sin_asignar" ? <img src="/images/sin_asignar.svg" alt="Usuario sin asignar" /> : <img src="/images/asignado.svg" alt="Usuario asignado" />}
                                {tokenInfo.role === "admin" || tokenInfo.role === "tecnico" ?
                                    <select
                                        onChange={(e) => actualizarTecnicoAsignado(e.target.value)}
                                        value={ticket.usuario_encargado || "sin_asignar"}
                                    >
                                        <option value="sin_asignar">Sin asignar</option>
                                        {tecnicos.map((tecnico) => (
                                            <option key={tecnico.usuario} value={tecnico.usuario}>
                                                {tecnico.nombre} {tecnico.apellidos} (@{tecnico.usuario})
                                            </option>
                                        ))}
                                    </select> :
                                    <p>{ticket.usuario_encargado === "sin_asignar" ? 'Sin asignar' : `@${ticket.usuario_encargado}`}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="grid-ticket">
                        <div className="datos-ticket">
                            <div className="datos-principales">
                                <div className="bloque-datos">
                                    <p>Ticket ID</p>
                                    <p>#{ticket.identificador}</p>
                                </div>
                                <div className="bloque-datos">
                                    <p>Nombre</p>
                                    <p>{ticket.nombre}</p>
                                </div>
                                <div className="bloque-datos">
                                    <p>Email</p>
                                    <p>{ticket.email}</p>
                                </div>
                                <div className="bloque-datos">
                                    <p>Teléfono</p>
                                    <p>{ticket.telefono}</p>
                                </div>
                                <div className="bloque-datos">
                                    <p>Webs</p>
                                    <p>{ticket.webs_del_cliente}</p>
                                </div>
                                <div className="bloque-datos">
                                    <p>Dpto</p>
                                    {tokenInfo.role === 'admin' ? (
                                        <select value={ticket.dpto} onChange={(e) => actualizarDpto(e.target.value)}>
                                            <option value="no_especificado">No especificado</option>
                                            <option value="desarrollo">Desarrollo</option>
                                            <option value="diseno">Diseño</option>
                                            <option value="marketing">Marketing</option>
                                            <option value="redes_sociales">Redes Sociales</option>
                                        </select>
                                    ) : ticket.dpto === 'no_especificado' ? (
                                        <p className='no-especificado'>No especificado</p>
                                    ) : ticket.dpto === 'desarrollo' ? (
                                        <p className="desarrollo">Desarrollo</p>
                                    ) : ticket.dpto === 'diseno' ? (
                                        <p className="diseno">Diseño</p>
                                    ) : ticket.dpto === 'marketing' ? (
                                        <p className="marketing">Marketing</p>
                                    ) : ticket.dpto === 'redes_sociales' ? (
                                        <p className="redes-sociales">Redes Sociales</p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="bloque-datos color-dato">
                                    <p>Prioridad</p>
                                    {tokenInfo.role === 'admin' ? (
                                        <select value={ticket.prioridad} onChange={(e) => actualizarPrioridad(e.target.value)}>
                                            <option value="no_urgente">No urgente</option>
                                            <option value="urgente">Urgente</option>
                                        </select>
                                    ) : ticket.prioridad === 'no_urgente' ? (
                                        <p className='sin-prioridad'>No Urgente</p>
                                    ) : ticket.prioridad === 'urgente' ? (
                                        <p className="alta">Urgente</p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="bloque-datos">
                                    <p>Estado</p>
                                    {tokenInfo.role === 'admin' ? (
                                    <select value={ticket.estado_del_ticket} onChange={(e) => actualizarEstadoDelTicket(e.target.value)}>
                                            <option value="abierto">Abierto</option>
                                            <option value="retenido">Retenido</option>
                                            <option value="cerrado">Cerrado</option>
                                        </select>
                                    ) : ticket.estado_del_ticket === 'abierto' ? (
                                        <p className="abierto">Abierto</p>
                                    ) : ticket.estado_del_ticket === 'retenido' ? (
                                        <p className="retenido">Retenido</p>
                                    ) : ticket.estado_del_ticket === 'cerrado' ? (
                                        <p className="cerrado">Cerrado</p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {tokenInfo.role === "admin" && (
                                    <button onClick={copiarTicket} className="copiar-ticket">
                                        <img src="/images/copia.svg" alt="Copiar" />
                                        <p>Copiar información del ticket</p>
                                    </button>
                                )}
                            </div>
                            <div className='datos-archivos'>
                                <p>Archivos aportados</p>
                                {ticket.archivos ? (
                                    <>
                                        <div className='grid-archivos'>
                                            {splitFiles(ticket.archivos)
                                                .slice(0, 3)
                                                .map((file, index) => (
                                                    <a target='_blank' href={file} key={index} className='archivo-ticket' download>
                                                        <img src={getIconUrl(file)} alt={`Archivo ${getFileName(file)}`} />
                                                        <p>{getFileName(file)}</p>
                                                        <img className='descargar' src="/images/descargar.svg" alt="Descargar" />
                                                    </a>
                                                ))}
                                        </div>
                                        {splitFiles(ticket.archivos).length > 3 && (
                                            <button className='ver-todos' onClick={() => setShowAllFiles(true)}>
                                                Ver todos
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <p className='no-archivos'>No se añadieron archivos</p>
                                )}
                                {showAllFiles && (
                                    <>
                                        <div className="layer-black"></div>
                                        <div className='popup-archivos'>
                                            <div className='popup-contenido'>
                                                <button className='cerrar-popup' onClick={() => setShowAllFiles(false)}>x</button>
                                                <h3>Galería de Archivos aportados</h3>
                                                <div className='grid-archivos-pop-up'>
                                                    {splitFiles(ticket.archivos).map((file, index) => (
                                                        <a target='_blank' href={file} key={index} className='archivo-ticket' download>
                                                            <img src={getIconUrl(file)} alt={`Archivo ${getFileName(file)}`} />
                                                            <p>{getFileName(file)}</p>
                                                            <img className='descargar' src="/images/descargar.svg" alt="Descargar" />
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="chat-ticket">
                            <p className='titulo-chat'>Chat</p>
                            <div className="chat" ref={chatRef}>
                                {usuarioCreador && (
                                    <div className="mensaje-chat">
                                        <img src={usuarioCreador.fotoPerfil} alt="Foto de perfil" />
                                        <div className="mensaje-texto">
                                            <p className='mensaje-nombre'>{usuarioCreador.nombre} {usuarioCreador.apellidos}</p>
                                            <p className='mensaje-usuario'>@{usuarioCreador.usuario}</p>
                                            <div className="contenido-mensaje">
                                                <p className='mensaje-descripcion' dangerouslySetInnerHTML={{ __html: ticket.descripcion }}></p>
                                            </div>
                                            <p className='mensaje-fecha'>{new Date(ticket.createdAt).toLocaleString()}</p>
                                        </div>
                                    </div>
                                )}
                                {ticket.chat && ticket.chat.map((msg, index) => (
                                    <div key={index} className="mensaje-chat">
                                        <img src={msg.fotoPerfil} alt="Foto de perfil" />
                                        <div className="mensaje-texto">
                                            <p className='mensaje-nombre'>{msg.nombre} {msg.apellidos}</p>
                                            <p className='mensaje-usuario'>@{msg.usuario}</p>
                                            <div className="contenido-mensaje">
                                                <p className='mensaje-descripcion' dangerouslySetInnerHTML={{ __html: msg.texto }}></p>
                                                {msg.archivos && msg.archivos.length > 0 && (
                                                    <div className="mensaje-archivos">
                                                        {msg.archivos.map((file, i) => (
                                                            <a key={i} href={file} target="_blank" rel="noopener noreferrer" className='archivo-adjunto-chat'>
                                                                <img src={getIconUrl(file)} alt="Archivo" />
                                                                <p>{getFileName(file)}</p>
                                                            </a>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            <p className='mensaje-fecha'>{new Date(msg.fecha).toLocaleString()}</p>
                                        </div>
                                    </div>
                                ))}
                                <div className='bottomRef' ref={bottomRef}></div>
                            </div>
                            <div className="enviar-mensaje">
                                {ticket.estado_del_ticket === 'cerrado' && (
                                    <p className="reopen-notice">Este ticket ha sido cerrado. Para reabrirlo, vuelve a enviar un mensaje.</p>
                                )}
                                {usuarioEscribiendo && (
                                    <div className="escribiendo">
                                        <img src="/images/chat-escribiendo.svg" alt="Escribiendo" />
                                        <p>{usuarioEscribiendo}</p>
                                    </div>
                                )}
                                {tokenInfo.role === "admin" &&
                                ticket.usuario_encargado === "sin_asignar" ? (
                                    <div className='chat-deshabilitado-admin'>
                                        <div className='mensaje-deshabilitado'>
                                            <p>Asigna el ticket a un técnico para habilitar el chat.</p>
                                        </div>
                                        <ReactQuill theme="snow" value={content} onChange={handleChange} />
                                        <button className='enviar-ticket' onClick={enviarMensaje} disabled={isSending}>
                                            {isSending ? "Enviando..." : "Enviar"}
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <div className="gestor-archivo-chat">
                                            {errorAlEnviar && (
                                                <p className='error error-chat'>El mensaje no puede estar vacío</p>
                                            )}
                                            <label className="custum-file-upload" htmlFor="file">
                                                <div className="icon">
                                                    <img src="/images/archive.svg" alt="Adjuntar" />
                                                </div>
                                                <div className="text">
                                                    <span>Adjuntar archivos (máx. 5MB por archivo)</span>
                                                </div>
                                                <input type="file" id="file" multiple onChange={handleFileChange} />
                                            </label>
                                            <div className="file-list-ticket">
                                                {selectedFiles.map((file, index) => (
                                                    <div key={index} className="file">
                                                        <img src="/images/nuevo-documento.svg" alt="Archivo" />
                                                        <p>{file.name}</p>
                                                        <button className="remove-file" onClick={() => handleRemoveFile(index)}>x</button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <ReactQuill theme="snow" value={content} onChange={handleChange} />
                                        <button className="enviar-ticket" onClick={enviarMensaje} disabled={isSending}>
                                            {isSending ? "Enviando..." : "Enviar"}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            <ToastContainer />
        </main>
    );
};

export default Ticket;
