import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Loader from '../../components/Loader';
import BASE_URL from '../../config/config';

const Servicios = () => {
    const [servicios, setServicios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = localStorage.getItem("AuthToken");
    let tokenInfo = {};

    if (token) {
        tokenInfo = jwtDecode(token);
    }

    const fetchServicios = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/servicios-holded/${tokenInfo.usuario}`);
            setServicios(response.data);
            setLoading(false);
        } catch (err) {
            setError('Error al obtener los servicios. Por favor, intenta nuevamente.');
            setLoading(false);
        }
    };

    useEffect(() => {
        if (tokenInfo.usuario) {
            fetchServicios();
        }
    }, [tokenInfo.usuario]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return (
            <main className='site-width'>
            <Link className='volver' to={'/dashboard'}>Volver al dashboard</Link>
            <div className="seccion-servicios">
                <div className="intro-servicios">
                    <div className="intro-servicios">
                        <h2>Servicios contratados</h2>
                        <p className='error' style={{width: 'fit-content'}}>No tienes servicios asociados. Si crees que esto es un error, <a href="/nuevo-ticket" style={{color: '#e74c3c'}}>abre un ticket</a> de soporte referente a desarrollo web e indica en el asunto: ERROR HLD_SRVCE.</p>
                    </div>
          
                </div>
                </div>
            </main>
        );
    }

    const getCardClass = (name) => {
        if (name.toLowerCase().includes('gold')) {
            return 'card-gold';
        } else if (name.toLowerCase().includes('silver')) {
            return 'card-silver';
        } else if (name.toLowerCase().includes('platinum')) {
            return 'card-platinum';
        }  else if (name.toLowerCase().includes('kit digital')) {
            return 'card-kit-digital';
        }  else {
            return 'card-default';
        }
    };

    return (
        <main className='site-width'>
            <Link className='volver' to={'/dashboard'}>Volver al dashboard</Link>
            <div className="seccion-servicios">
                <div className="intro-servicios">
                    <h2>Servicios contratados</h2>
                    <p>Estos son los servicios asociados a tu cuenta.</p>
                </div>
                {servicios.length > 0 ? (
                    <div className='grid-servicios'>
                        {servicios.map((servicio, index) => (
                            <div className={`${getCardClass(servicio.name)}`} key={index}>
                                <h3>{servicio.name}</h3>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="intro-servicios">
                        <h2>Servicios contratados</h2>
                        <p className='error'>No tienes servicios asociados. Si crees que esto es un error, abre un ticket de soporte referente a desarrollo web.</p>
                    </div>
                )}
            </div>
        </main>
    );
}

export default Servicios;
