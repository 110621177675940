import React from 'react'

const Versiones = () => {
  return (
    <main className='site-width'>
        <div className="control-versiones">
            <h1>Versión actual <span>v1.1.1</span></h1>
            <div className="bloque-versiones">
            <div className="bloque-version">
                    <p>v1.1.1</p>
                    <ul>
                       <li>Ahora se puede actualizar la foto de perfil desde tu dashboard.</li>
                       <li>Seguridad y comprobaciones mejoradas.</li>
                       <li>Conversión de imágenes de perfil a webp.</li>
                    </ul>
                </div> 
                <div className="bloque-version">
                    <p>v1.1.0</p>
                    <ul>
                        <li>Notificaciones de cierre de ticket para clientes.</li>
                        <li>Autocierre de tickets por inactividad (3 días de inactividad).</li>
                        <li>SENTRY añadido.</li>
                    </ul>
                </div>  
                <div className="bloque-version">
                    <p>v1.0.4</p>
                    <ul>
                        <li>Mejoras de carga e interfaz.</li>
                        <li>Visualizador de notificaciones en la cabecera.</li>
                        <li>Mejoras en sistemas internos de administración.</li>
                        <li>Arreglo de bugs.</li>
                        <li>¡Toque navideño!</li>
                    </ul>
                </div>  
                <div className="bloque-version">
                    <p>v1.0.3</p>
                    <ul>
                        <li>Mejoras visuales en móvil.</li>
                        <li>Adjunción de archivos disponibles en mensajes de chat.</li>
                        <li>Galería de archivos de ticket disponible.</li>
                        <li>Método de recuperación de contraseña añadido.</li>
                    </ul>
                </div>
                <div className="bloque-version">
                    <p>v1.0.2</p>
                    <ul>
                        <li>Ahora cuando se verifica una cuenta, el usuario recibe una notificación.</li>
                        <li>Corrección de errores visuales en los tickets.</li>
                        <li>Notificación al técnico mejorada.</li>
                        <li>Optimización y arreglos en la interfaz administrativa.</li>
                        <li>Sistema de sugerencias/mejoras añadido (Canny.io).</li>
                    </ul>
                </div>

                <div className="bloque-version">
                    <p>v1.0.1</p>
                    <ul>
                        <li>Bugs iniciales de la plataforma solucionados.</li>
                    </ul>
                </div>
            </div>
        </div>
       
    </main>
  )
}

export default Versiones
