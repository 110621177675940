import { useState, useEffect } from "react";

const MensajePanel = () => {
  const STORAGE_KEY = "MensajePanel";
  const [isVisible, setIsVisible] = useState(() => {
    return localStorage.getItem(STORAGE_KEY) !== "true";
  });

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem(STORAGE_KEY, "true");
  };

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEY) === "true") {
      setIsVisible(false);
    }
  }, []);

  if (!isVisible) return null;

  return (
    <div className="mensaje-panel-warning">
      <button onClick={handleClose}>
        X
      </button>
        <p>Atención: Para optimizar la gestión de tickets global, hemos implementado un cierre automático por inactividad. Si un ticket no recibe respuesta en un plazo de 3 días, se cerrará automáticamente y recibirás una notificación en tu correo electrónico.</p>
    </div>
  );
};

export default MensajePanel;
