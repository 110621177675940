import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Importación corregida
import BASE_URL from '../config/config';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

const Notificaciones = () => {
  const [notificaciones, setNotificaciones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
    fetchNotificaciones();
  };

  const fetchNotificaciones = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("AuthToken");
      if (!token) {
        throw new Error("Token de autenticación no encontrado.");
      }

      const decodedToken = jwtDecode(token);
      if (!decodedToken || !decodedToken.usuario || !decodedToken.role) {
        throw new Error("El token es inválido o carece de información requerida.");
      }

      const { usuario, role } = decodedToken;
      const response = await axios.get(`${BASE_URL}/ticket/notificaciones/${usuario}`, {
        params: { role },
      });

      if (response.status === 200) {
        setNotificaciones(response.data.tickets);
      } else {
        throw new Error("Error inesperado en la respuesta del servidor.");
      }
    } catch (err) {
      console.error('Error al obtener notificaciones:', err);
      setError('Ocurrió un error al cargar las notificaciones.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchNotificaciones();
    const interval = setInterval(fetchNotificaciones, 10000); // Actualiza cada 10 segundos

    return () => clearInterval(interval); // Limpia el intervalo al desmontar
  }, []);

  return (
    <div className='modal-notificaciones' ref={dropdownRef}>
      <button className='notificaciones' onClick={toggleDropdown}>
        <img src="/images/notificacion.svg" alt="Icono Notificaciones" />
        {
          notificaciones.length > 0 && (
            <div className='num-notificacion'>{notificaciones.length}</div>
          )
        }
      </button>
      {isDropdownOpen && (
        <div className="dropdown-notificaciones">
          {isLoading ? (
            <div className="grid-notificaciones">
              <h3>Notificaciones pendientes <button className='refresh-button' onClick={fetchNotificaciones}><img src="/images/refresh.svg" alt="Refrescar" /></button></h3>
              <ContentLoader height={400} width="100%">
                <rect x="0" y="10" rx="4" ry="4" width="100%" height="10" />
                <rect x="0" y="30" rx="4" ry="4" width="100%" height="10" />
                <rect x="0" y="60" rx="4" ry="4" width="100%" height="10" />
                <rect x="0" y="80" rx="4" ry="4" width="100%" height="10" />
                <rect x="0" y="110" rx="4" ry="4" width="100%" height="10" />
                <rect x="0" y="130" rx="4" ry="4" width="100%" height="10" />
              </ContentLoader>
            </div>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className='grid-notificaciones'>
              <h3>Notificaciones pendientes <button className='refresh-button' onClick={fetchNotificaciones}><img src="/images/refresh.svg" alt="Refrescar" /></button></h3>
              {notificaciones.length > 0 ? (
                notificaciones.map((ticket, index) => (
                  <Link 
                    to={`/ticket/${ticket.identificador}`} 
                    className='notificacion' 
                    key={index}
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    <p className='not-id'><b>#{ticket.identificador}</b> - {ticket.asunto}</p>
                    <p>{ticket.nombre} respondió al ticket.</p>
                    <p>
                      {new Intl.DateTimeFormat('es-ES', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      }).format(new Date(ticket.updatedAt))}
                    </p>
                  </Link>
                ))
              ) : (
                <p className='no-pendiente'>No hay tickets pendientes de responder</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notificaciones;
