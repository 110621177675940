import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BASE_URL from '../../../config/config';

const AdminPrePanel = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [longitudUsuarios, setLongitudUsuarios] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [totalProyectos, setTotalProyectos] = useState(0);

  const handleLinkClick = () => {
    window.scrollTo({ top: 0});
};

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/todos-usuarios-conteo`);
        const usuarios = response.data.total || 0;
        console.log(usuarios)
        setUsuarios(usuarios);
        setLongitudUsuarios(usuarios);
      } catch (error) {
        console.error('Error al obtener los usuarios:', error);
      }
    };


    const fetchTotalTickets = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/tickets/total-conteo`);
        console.log(response)
        const tickets = response.data.total || 0;
        setTotalTickets(tickets);
      } catch (error) {
        console.error('Error al obtener los tickets:', error);
      }
    };

    const fetchTotalProyectos = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/proyectos`);
        console.log(response.data)
        const proyectos = response.data.proyectos.length || 0;
        setTotalProyectos(proyectos);
      } catch (error) {
        console.error('Error al obtener los proyectos:', error);
      }
    };

    fetchUsuarios();
    fetchTotalTickets();
    fetchTotalProyectos();
  }, []);


  return (
    <div className='admin-prepanel'>
        <Link to={'/dashboard/tickets-manager'} className='prepanel-card' onClick={handleLinkClick}>
            <h3>Gestionar Tickets</h3>
            <p className='prepanel-number'>Responde y administra tickets por departamento.</p>
            <p className='prepanel-warn-ticket'>Incidencias abiertas: {totalTickets}</p>
            <button>Ir al panel</button>
        </Link>
        <Link to={'/dashboard/users-manager'} className='prepanel-card' onClick={handleLinkClick}>
            <h3>Gestionar Usuarios</h3>
            <p className='prepanel-number'>Administra y verifica los usuarios de la plataforma.</p>
            <p className='prepanel-warn-usuarios'>Usuarios: {longitudUsuarios}</p>
            <button>Ir al panel</button>
        </Link>
         <Link to={'/dashboard/tracking-proyectos'} className='prepanel-card'>
            <h3>Tracking de Proyectos</h3>
            <p className='prepanel-number'>Informa al cliente de las fases de realización de su proyecto.</p>
            <p className='prepanel-warn-usuarios'>Proyectos activos: {totalProyectos}</p>
            <button>Ir al panel</button>
        </Link>
    </div>
  );
};

export default AdminPrePanel;
