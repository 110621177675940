import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import axios from 'axios';
import BASE_URL from '../../config/config';

const FotoPerfil = () => {
    const token = localStorage.getItem("AuthToken");
    let tokenInfo = {};

    if (token) {
        tokenInfo = jwtDecode(token);
    }

    const [profilePicUrl, setProfilePicUrl] = useState(tokenInfo.fotoPerfil || "/images/usuario.png");

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
    
        if (file) {
            const formData = new FormData();
            formData.append('fotoPerfil', file);
    
            try {
                const response = await axios.put(
                    `${BASE_URL}/usuario/actualizar-imagen/${tokenInfo.usuario}`,
                    formData,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
    
                if (response.data.success) {
                    // **Actualizar la imagen de perfil**
                    setProfilePicUrl(response.data.user.fotoPerfil);
    
                    // **Actualizar el token en localStorage**
                    localStorage.setItem("AuthToken", response.data.token);
    
                    alert("Imagen actualizada correctamente.");
                    window.location.reload(); // Recargar la página para aplicar el nuevo token
                } else {
                    alert(response.data.message);
                }
            } catch (error) {
                console.error('Error al actualizar la imagen:', error);
                alert("Error al actualizar la imagen.");
            }
        }
    };
    
    return (
        <div className='grid-profile-pic'>
            <img src={profilePicUrl || "/images/usuario.svg"} alt="Foto Perfil" className="profile-pic" />
            <label className="update-profile-pic">
                <img src="/images/circulo.svg" alt="Lápiz" />
                <input type="file" accept="image/*" onChange={handleFileChange} />
            </label>
        </div>
    );
};

export default FotoPerfil;