import axios from 'axios';
import uniqid from 'uniqid';
import { jwtDecode } from "jwt-decode";
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Estilos Quill
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Estilos de react-toastify
import { useEffect } from 'react';
import BASE_URL from '../../config/config';
import Verificacion from '../../components/Verificacion';

const NuevoTicket = () => {  
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar el botón

  
  const [soporteDisponible, setSoporteDisponible] = useState(false);
  const [diaSemana, setDiaSemana] = useState(null); // Definir diaSemana como estado

  useEffect(() => {
    const ahora = new Date();
    const diaSemanaActual = ahora.getDay(); // 0 (Domingo) a 6 (Sábado)
    const hora = ahora.getHours();
    
    // Verificar si es Lunes a Jueves entre las 8:00 am y las 16:00 pm
    const esLunesAJueves = diaSemanaActual >= 1 && diaSemanaActual <= 4;
    const esViernes = diaSemanaActual === 5;
    const esHoraHabil = hora >= 8 && ((esViernes && hora < 15) || (!esViernes && hora < 16));

    if ((esLunesAJueves || esViernes) && esHoraHabil) {
      setSoporteDisponible(true);
    } else {
      setSoporteDisponible(false);
    }

    // Establecer el valor de diaSemana
    setDiaSemana(diaSemanaActual);
  }, []);

  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [asunto, setAsunto] = useState('');
  const location = useLocation();
  const departmentFromState = location.state?.department || 'no_especificado'; // Recibir el departamento

  const [departamento, setDepartamento] = useState(departmentFromState); // Inicializar con el valor recibido
  
  const [prioridad, setPrioridad] = useState('no_urgente');

  // Definición de tokenInfo
  const token = localStorage.getItem("AuthToken");
  let tokenInfo = {};
  if (token) {
    tokenInfo = jwtDecode(token);
  }

  if (tokenInfo.estado === "verificar") {
    return <Verificacion />;
  }
  const handleChange = (value) => {
    setContent(value);
  };

  const handleAttachmentChange = (e) => {
    const files = e.target.files;
    const maxFileSize = 5 * 1024 * 1024; // 5MB en bytes
    const validFiles = [];
  
    for (let i = 0; i < files.length; i++) {
      if (files[i].size <= maxFileSize) {
        validFiles.push(files[i]);
      } else {
        toast.error(`El archivo ${files[i].name} excede el tamaño máximo de 5MB.`);
      }
    }
  
    setAttachments(validFiles);
  };
  

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  const handleAsuntoChange = (e) => {
    setAsunto(e.target.value);
  };

  const handleDepartamentoChange = (e) => {
    setDepartamento(e.target.value);
  };
  const handlePrioridadChange = (e) => {
    setPrioridad(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (asunto.trim().length < 5) {
        toast.error('El asunto no puede estar vacío y debe tener al menos 5 caracteres.');
        return;
    }

    if (content.trim().length < 5) {
        toast.error('La descripción no puede estar vacía y debe tener al menos 5 caracteres.');
        return;
    }

    setIsSubmitting(true);

    const identificador_unico = uniqid.time();
    const uniqid_up = identificador_unico.toUpperCase();

    const formData = new FormData();
    formData.append('identificador', uniqid_up);
    formData.append('nombre', tokenInfo.nombre);
    formData.append('apellidos', tokenInfo.apellidos);
    formData.append('telefono', tokenInfo.telefono);
    formData.append('email', tokenInfo.email);
    formData.append('webs_del_cliente', tokenInfo.webs_del_cliente);
    formData.append('asunto', asunto);
    formData.append('descripcion', content);
    formData.append('dpto', departamento);
    formData.append('usuario_creador', tokenInfo.usuario);
    formData.append('prioridad', prioridad);

    for (let i = 0; i < attachments.length; i++) {
        formData.append('archivos', attachments[i]);
    }

    try {
        // Crear el ticket
        const response = await axios.post(`${BASE_URL}/crear-ticket`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });

        navigate(`/ticket-creado/${response.data.identificador}`); 

        // Llamar a la nueva ruta para notificar a administrador
        await axios.post(`${BASE_URL}/notificar-nuevo-ticket-admin`, {
            asunto: asunto,
            identificador: uniqid_up,
            usuario_creador: tokenInfo.usuario,
            descripcion: content,
            nombre: tokenInfo.nombre,
            apellidos: tokenInfo.apellidos
        });

        await axios.post(`${BASE_URL}/notificar-nuevo-ticket-cliente`, {
          asunto: asunto,
          identificador: uniqid_up,
          usuario_creador: tokenInfo.usuario,
          descripcion: content,
          email_cliente: tokenInfo.email,
          nombre_cliente: tokenInfo.nombre
      });

        console.log('Ticket creado:', response.data);
    } catch (error) {
        toast.error('Error al crear el ticket. Inténtalo de nuevo más tarde.');
        console.error('Error al crear el ticket:', error);
        setIsSubmitting(false); // Desbloquear el botón si hay error
    }
  };


  return (
    <main className='nuevo-ticket site-width'>
      <div className="bloque-ticket">
        <div className="texto-ticket">
          <div className="intro-ticket">
            <h2>👋🏻 ¡Hola {tokenInfo.nombre}!</h2>
            <p>¿Tienes algún problema o simplemente tarea nueva a realizar?</p>
            <p>Rellena el siguiente formulario y te asignaremos a un técnico para que gestione tu tarea/incidencia lo antes posible a través de un chat en tiempo real.</p>
          </div>
          <div className="datos-preticket">
            <p>¿Son correctos tus datos?<Link to={"/dashboard"}>Editar</Link></p>
            <p className='parrafo-preticket'>Usaremos la siguiente información para notificarte y contactarte en caso necesario.</p>
            <div className="contacto-info-bloque">
            <p className='contacto-info'><span>Nombre:</span> {tokenInfo.nombre}</p>
            <p className='contacto-info'><span>Apellidos:</span> {tokenInfo.apellidos}</p>
              <p className='contacto-info'><span>Teléfono:</span> {tokenInfo.telefono}</p>
              <p className='contacto-info'><span>Email:</span> {tokenInfo.email}</p>
              <p className='contacto-info'><span>Sitios en mantenimiento:</span> {tokenInfo.webs_del_cliente}</p>
            </div>
          </div>
        </div>
        <div className="formulario-ticket">
          <h3>¡Estás a punto de crear un ticket!</h3>

          {soporteDisponible ? '': <div className='adhoc-warning warning-ticket'><p>Nuestros técnicos no están disponibles fuera de horario laboral, al crear un ticket, se responderá dentro de horario por orden de llegada.</p></div>} 

          <div className="bloque-form">
            <label>¿Qué departamento se encargará de tu tarea/incidencia?</label>
            <select name="opciones" id="opciones" value={departamento} onChange={handleDepartamentoChange}>
              <option value="no_especificado">Seleccione el departamento correspondiente</option>
              <option value="diseno">Diseño Gráfico</option>
              <option value="desarrollo">Desarrollo Web</option>
              <option value="marketing">Marketing</option>
              <option value="redes_sociales">Redes Sociales</option>
            </select>
            {
              departamento === "desarrollo" ? <p className='desc-dpto-desarrollo'>Si tu tarea/incidencia está relacionada con tu página web, software a medida, correo electrónico, dominios y hosting.</p>: ''
            }
            {
              departamento === "diseno" ? <p className='desc-dpto-diseno'>Si tu tarea/incidencia está relacionada con branding, diseño editorial, diseño para eventos, papelería, rotulación, material promocional, producción de video corporativo/publicitario, edición fotográfica/postproducción de video y fotografía corporativa/producto.</p>: ''
            }
            {
              departamento === "marketing" ? <p className='desc-dpto-marketing'>Si tu tarea/incidencia está relacionada con SEO, campañas de pago (SEM + SMM), campañas de email marketing, informes mensuales de analítica web o análisis y diseño de experiencias e interfaces de usuario.</p>: ''
            }
            {
              departamento === "redes_sociales" ? <p className='desc-dpto-redes_sociales'>Si tu tarea/incidencia está relacionada con la dinamización de tus redes sociales.</p>: ''
            }
            
          </div>
          <div className="bloque-form">
            <label>Asunto</label>
            <input type="text" placeholder='Introduzca un título a tu tarea/incidencia...' value={asunto} onChange={handleAsuntoChange} />
          </div>
          <div className="bloque-form">
            <label>Describa detalladamente tu tarea/incidencia</label>
            <ReactQuill
              theme="snow"
              value={content}
              onChange={handleChange}
            />
          </div>
          <div className="bloque-form">
            <label>Adjuntar archivos <span className='opcional-msg'>(Opcional)</span></label>
            <p>Si no puedes optimizar tus archivos, te sugerimos utilizar <a href='https://wetransfer.com/' target='_blank'>WeTransfer</a> y proporcionarnos el enlace de descarga en la descripción de tu tarea.</p>
            <label htmlFor="file" className="labelFile">
              <p>Arrastra y suelta aquí tus archivos<br/>(.pdf, .doc, .docx, .jpg, .png, .jpeg, .webp, .pages y .html)<br/>Peso máximo por archivo: 5MB</p>
            <input accept=".pdf,.doc,.docx,.jpg,.png,.jpeg,.webp,.pages,.html" name="text" id="file" type="file" multiple onChange={handleAttachmentChange} />
            </label>
            {attachments && attachments.length > 0 && (
                <div className='files-grid'>
                  {Array.from(attachments).map((file, index) => (
                    <div className='file'>
                      <img src="/images/nuevo-documento.svg" alt="Archivo" />
                      <p key={index}>{file.name}</p>
                      <button className='remove-file' onClick={() => handleRemoveAttachment(index)}>x</button>
                    </div>
                  ))}
                </div>
            )}
          </div>

          <div className="bloque-form">
            <label>¿Es urgente?</label>
            <select name="opciones_prioridad" id="opciones_prioridad" value={prioridad} onChange={handlePrioridadChange}>
              <option value="no_urgente">No</option>
              <option value="urgente">Si</option>
            </select>
          </div>
          <button
            className='enviar-ticket'
            onClick={handleSubmit}
            disabled={isSubmitting} // Bloquear el botón
          >
            {isSubmitting ? 'Enviando...' : 'Enviar'}
          </button>        </div>
      </div>
      <ToastContainer />
    </main>
  )
}

export default NuevoTicket;
